import cssLogo from "../assets/img/cssLogo.png";
import htmlLogo from "../assets/img/htmlLogo.png";
import jsLogo from "../assets/img/jsLogo.png";
import reactLogo from "../assets/img/reactLogo.png";
import reactNLogo from "../assets/img/reactNLogo.png";
import nodeLogo from "../assets/img/nodeLogo.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                Experience meets expertise: showcasing my skills to power your
                projects.
              </p>
              <Carousel
                responsive={responsive}
                autoPlay={true}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
                slidesToSlide={1}
                arrows={false}
              >
                <div className="item">
                  <img src={reactLogo} alt="Image" />
                  <h5>React</h5>
                </div>
                <div className="item">
                  <img
                    src={reactNLogo}
                    alt="Image"
                    style={{ marginTop: "10px", marginBottom: "3px" }}
                  />
                  <h5>React Native</h5>
                </div>
                <div className="item">
                  <img src={jsLogo} alt="Image" />
                  <h5>JavaScript</h5>
                </div>
                <div className="item">
                  <img src={nodeLogo} alt="Image" />
                  <h5>Node</h5>
                </div>
                <div className="item">
                  <img src={htmlLogo} alt="Image" />
                  <h5>HTML</h5>
                </div>
                <div className="item">
                  <img src={cssLogo} alt="Image" />
                  <h5>CSS</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
